<template>
  <div class="w1200 boardWrap" v-if="list">
    <div class="boardName">
      <div class="dfx gap10">
        <div class="boardbtn dfx gap10">
          <ui-button :link="'accountQna'" :className="'grbbtn'" :text="$t('front.board.bankAccountQna')" @click="getAcc"/>
          <ui-button :link="'qnaWrite'" :className="'grsbtn'" :text="$t('front.board.qnaWrite')" @click="onClickWrite"/>
        </div>
      </div>
    </div>
    <board-filter @onSearch="onSearch"/>
    <div class="board">
      <ul class="boardw first">
        <li class="w115">{{$t('front.board.check')}}</li>
        <li class="w115">{{$t('front.board.status')}}</li>
        <li class="w115">{{$t('front.board.type')}}</li>
        <li class="w430">{{$t('front.board.title')}}</li>
        <li class="w140">{{$t('front.board.regDate')}}</li>
        <li class="w115">{{$t('front.board.delete')}}</li>
      </ul>
      <template v-if="list.length > 0">
        <template v-for="item in list" v-bind:key="item.boardIdx">
          <ul class="boardw">
            <li class="w115"><span class="ocmsg" :class="item.isReadAdmin === 'Y' ? 'openmsg': 'closemsg'"></span></li>
            <li class="w115">
              <span v-if="item.status === 'ANSWER'" class="rd-impor">{{$t('front.qnaStatus.answer')}}</span>
              <span v-if="item.status === 'WAIT'" class="bl-impor">{{$t('front.qnaStatus.wait')}}</span>
            </li>
            <li class="w115 bl-impor">{{$t('front.qnaTypeCategory.' + item.faqType)}}</li>
            <li class="w430 textleft" @click.prevent="onClickRead(item.boardIdx)">
              <a href="#" >
                {{item.title}}
                <span class="newicon rdbg ml5">N</span>
              </a>
            </li>
            <li class="w140">{{dateFormatForBoard(item.regDate)}}</li>
            <li class="w115"><span class="nbicon red" @click.prevent="onClickRemove(item.boardIdx)">{{$t('front.board.delete')}}</span></li>
          </ul>
        </template>
      </template>
      <template v-else>
        <ul class="boardw">
          <li class="">{{$t('front.board.noAsk')}}</li>
        </ul>
      </template>
    </div>
    <div class="mt20">
       <pagination :pageNum="pageInfo.page"
                   :pageSize="pageInfo.count_per_list"
                   :totalCount="pageInfo.tatal_list_count"
                   @goToPage="loadList"/>
    </div>
  </div>

</template>

<script>
import Pagination from '@/components/ui/Pagination'
import UiButton from '@/components/ui/UiButton'
import BoardFilter from '@/components/common/BoardFilter'
import { mapState } from 'vuex'

export default {
  name: 'qna',
  components: {
    BoardFilter,
    UiButton,
    Pagination
  },
  data () {
    return {
      list: null,
      boardType: 'faq',
      searchParam: {
        searchOption: '',
        searchValue: ''
      },
      idxList: [],
      category: ''
    }
  },
  async created () {
    await this.loadList()
  },
  computed: {
    ...mapState([
      'userData'
    ])
  },
  methods: {
    async getAcc () {
      const title = '계좌문의입니다.'
      const content = '계좌문의입니다.'
      const params = {
        title: title,
        content: content,
        boardType: 'faq',
        category: '',
        faqType: 'bankaccount'
      }

      await this.setSaveAccountBoard(params, async function () {
        await this.loadList()
      }.bind(this))
    },
    onCategoryChange (category) {
      this.category = category
      this.loadList()
    },
    onSearch (value) {
      this.searchParam.searchOption = value.type
      this.searchParam.searchValue = value.text

      this.loadList()
    },
    async loadList (page) {
      this.pageInfo.page = page || 1
      const params = {
        boardType: this.boardType,
        category: this.category,
        memId: this.userData.memId
      }

      const searchValue = this.searchParam.searchValue
      if (searchValue) {
        params.searchOption = this.searchParam.searchOption
        params.searchValue = this.searchParam.searchValue
      }

      const result = await this.getBoardList(params, this.pageInfo.page)
      console.log(result)
      result.list.forEach(item => {
        item.isContentOpen = false
      })
      this.list = result.list
      this.idxList = result.idxList
    },
    onClickRead (id) {
      if (!id) id = 1
      this.$router.push({ name: 'qnaRead', params: { boardIdx: id } })
    },
    onClickWrite () {
      this.$router.push({ name: 'qnaWrite' })
    },
    onClickRemove (boardIdx) {
      if (boardIdx) {
        const params = {
          boardIdx: boardIdx,
          boardType: this.boardType,
          delYn: 'Y'
        }

        this.setSaveBoard(params, 'delete', () => {
          this.loadList()
        })
      }
    }
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
