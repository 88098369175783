<template>
   <navi-bar>
     <img src="@/assets_mobile/img/icon_customer.png"/>
     <span>{{$t('front.common.qna')}}<em>Qna</em></span>
   </navi-bar>
  <section class="sub-box">
    <cs-nav-mobile />
    <board-filter-mobile @onSearch="onSearch"/>
    <div class="writebtn">
<!--      <select class="select">-->
<!--        <option>문의분류</option>-->
<!--        <option>전체</option>-->
<!--        <option>라이브카지노</option>-->
<!--        <option>슬롯</option>-->
<!--        <option>충전/환전</option>-->
<!--        <option>베팅관련</option>-->
<!--        <option>기타</option>-->
<!--      </select>-->
      <a class="btnt" @click="getAcc">{{$t('front.board.bankAccountQna')}}</a>
      <a @click="goPageByName('qnaWrite')" class="btno">{{$t('front.board.qnaWrite')}}</a>
    </div>
    <!--div class="coupontab coupontab2">
      <a :class="{'on': category == ''}" @click="onCategoryChange('')">{{$t('front.gameCategory.all')}}</a>
      <a :class="{'on': category == 'livecasino'}" @click="onCategoryChange('livecasino')">{{$t('front.gameCategory.livecasino')}}</a>
      <a :class="{'on': category == 'slot'}" @click="onCategoryChange('slot')">{{$t('front.gameCategory.slot')}}</a>
      <--a :class="{'on': category == 'sports'}" @click="onCategoryChange('sports')">{{$t('front.gameCategory.sports')}}</a>
      <a :class="{'on': category == 'esports'}" @click="onCategoryChange('esports')">{{$t('front.gameCategory.esports')}}</a>
      <a :class="{'on': category == 'minigame'}" @click="onCategoryChange('minigame')">{{$t('front.gameCategory.minigame')}}</a>
      <a :class="{'on': category == 'virtualgame'}" @click="onCategoryChange('virtualgame')">{{$t('front.gameCategory.virtualgame')}}</a->
      <a :class="{'on': category == 'cash'}" @click="onCategoryChange('cash')">{{$t('front.gameCategory.cash')}}</a>
      <a :class="{'on': category == 'betting'}" @click="onCategoryChange('betting')">{{$t('front.gameCategory.betting')}}</a>
      <a :class="{'on': category == 'etc'}" @click="onCategoryChange('etc')">{{$t('front.gameCategory.etc')}}</a>
    </div-->
    <div class="qnatab">
      <a :class="{'on': category == ''}" @click="onCategoryChange('')">{{$t('front.gameCategory.all')}}</a>
      <a :class="{'on': category == 'livecasino'}" @click="onCategoryChange('livecasino')">{{$t('front.gameCategory.livecasino')}}</a>
      <a :class="{'on': category == 'slot'}" @click="onCategoryChange('slot')">{{$t('front.gameCategory.slot')}}</a>
      <a :class="{'on': category == 'cash'}" @click="onCategoryChange('cash')">{{$t('front.gameCategory.cash')}}</a>
      <a :class="{'on': category == 'betting'}" @click="onCategoryChange('betting')">{{$t('front.gameCategory.betting')}}</a>
      <a :class="{'on': category == 'etc'}" @click="onCategoryChange('etc')">{{$t('front.gameCategory.etc')}}</a>
    </div>

    <div class="msgwrap qnalistwrap">
      <template v-for="item in list" v-bind:key="item.boardIdx">
        <ul :class="item.isReadAdmin === 'Y' ? 'on': 'off'" >
          <li class="msgcon">
            <a @click="item.isContentOpen = !item.isContentOpen"><!-- @click.prevent="onClickRead(item.boardIdx)" -->
              <span class="date">
                <em v-if="item.status === 'WAIT'" class="waiticon">{{$t('front.qnaStatus.wait')}}</em>
                <em v-if="item.status === 'ANSWER'" class="compleicon">{{$t('front.qnaStatus.answer')}}</em>
                {{dateFormatForMobile(item.regDate)}}
              </span>
              <span class="subject">
                <em class="servicename">{{$t('front.qnaTypeCategory.' + item.faqType)}}</em>
                <em class="subjecttxt1">{{item.title}}</em>
                <em class="newicon">N</em>
              </span>
            </a>
            <span class="arrow" @click="item.isContentOpen = !item.isContentOpen">
              <img src="@/assets_mobile/img/icon_top_gn.svg"  v-if="item.isContentOpen"/>
              <img src="@/assets_mobile/img/icon_bottom_gr.svg" v-if="!item.isContentOpen"/>
            </span><!-- icon_bottom_gr -->
          </li>
          <li class="qnalistread" :class="{'complet': item.status === 'ANSWER', 'progress': item.status === 'WAIT'}" v-if="item.isContentOpen">
            <p class="writing">[Q] {{item.content}}</p>
            <div class="reply" v-if="item.comment.length > 0">
              <p>[A] {{item.comment[0].content}}</p>
            </div>
          </li>
          <!--li class="trashicon" @click.prevent="onClickRemove(item.boardIdx)"></li-->
        </ul>
      </template>

<!--      <ul class="off">-->
<!--        <li class="msgicon"></li>-->
<!--        <li class="msgcon">-->
<!--          <a>-->
<!--            <span class="subject"><em class="compleicon">답변완료</em>서버 점검 안내</span>-->
<!--            <span class="date"><em class="servicename">스포츠</em>2021.06.28</span>-->
<!--          </a>-->
<!--        </li>-->
<!--        <li class="trashicon"></li>-->
<!--      </ul>-->
<!--      <ul class="off">-->
<!--        <li class="msgicon"></li>-->
<!--        <li class="msgcon">-->
<!--          <a>-->
<!--            <span class="subject"><em class="compleicon">답변완료</em>서버 점검 안내</span>-->
<!--            <span class="date"><em class="servicename">카지노</em>2021.06.28</span>-->
<!--          </a>-->
<!--        </li>-->
<!--        <li class="trashicon"></li>-->
<!--      </ul>-->
<!--      <ul class="off">-->
<!--        <li class="msgicon"></li>-->
<!--        <li class="msgcon">-->
<!--          <a>-->
<!--            <span class="subject"><em class="compleicon">답변완료</em>서버 점검 안내</span>-->
<!--            <span class="date"><em class="servicename">가상게임</em>2021.06.28</span>-->
<!--          </a>-->
<!--        </li>-->
<!--        <li class="trashicon"></li>-->
<!--      </ul>-->

    </div>

    <!--div class="btnwrap">
      <a class="selec">계좌문의</a>
      <a @click="goPageByName('qnaWrite')" class="ok">문의작성</a>
    </div-->
    <pagination-mobile v-if="pageInfo"
                       :pageNum="pageInfo.page"
                       :pageSize="pageInfo.count_per_list"
                       :totalCount="pageInfo.tatal_list_count"
                       @goToPage="loadList()"
    />
  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import CsNavMobile from '@/views_mobile/member/cs/nav'
import Qna from '@/views/member/cscenter/qna/list'
import BoardFilterMobile from '@/components/common/mobile/BoardFilterMobile'

export default {
  name: 'QnAList',
  components: {
    BoardFilterMobile,
    CsNavMobile,
    NaviBar,
    PaginationMobile
  },
  mixins: [Qna]
}
</script>

<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style>
.morebtn {margin-bottom: 10px !important;}
</style>
